<template>
  <Deskrecv />
</template>

<script>
import Deskrecv from "../../../components/factory_deskrecv";

export default {
  name: "factory_deskrecv",
  components: {
    Deskrecv,
  },
};
</script>